import { authFetch } from "./fetchConfig";
import {
  reformatContacts,
  reformatPastActivitiesResponse,
} from "../helpers/deal-helpers";
import {
  setDealContacts,
  setDealError,
  setDealInfo,
  setDealPastActivities,
  setDealSaveSuccess,
  setDealStatus,
  setIsLoadingDeal,
} from "../redux/reducers/dealPage-reducer";

const getDealInfo = async (dispatch, dealId) => {
  dispatch(setIsLoadingDeal(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}`
  );
  const responseData = await response.json();
  if (response.status === 200) {
    dispatch(setDealInfo(responseData.deal));
  } else {
    console.error("something went wrong: ", responseData);
  }
  dispatch(setIsLoadingDeal(false));
};

const getDealContacts = async (dispatch, dealId) => {
  dispatch(setIsLoadingDeal(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/contacts`
  );
  const responseData = await response.json();
  if (response.status === 200) {
    dispatch(setDealContacts(responseData.rwi_contacts));
  } else {
    dispatch(setDealError(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingDeal(false));
};

const putDealContacts = async (
  dispatch,
  dealId,
  contacts,
  setErrorsToInput
) => {
  dispatch(setIsLoadingDeal(true));
  const dataToSend = reformatContacts(contacts);
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/contacts`,
    {
      method: "PUT",
      body: JSON.stringify(dataToSend),
      headers: { "Content-type": "application/json" },
    }
  );
  if (!response.ok) {
    const err = await response.json();
    dispatch(setDealError(err?.errors[0]?.message));
    if (err?.errors[0]?.detail) {
      const errEntries = Object.entries(err?.errors[0]?.detail);
      // NEEDS REFACTORING!!!!!!
      errEntries.forEach(([key, value]) => {
        if (key === "due_diligence_providers_count") {
          setErrorsToInput(key, { type: "api", message: value[0] });
        } else if (key === "due_diligence_providers") {
          Object.entries(value).forEach(([index, obj]) => {
            Object.entries(obj).forEach(([fieldName, val]) => {
              setErrorsToInput(`${key}.${index}.${fieldName}`, {
                type: "api",
                message: val[0],
              });
            });
          });
        } else {
          Object.entries(value).forEach(([innerKey, innerValue]) => {
            setErrorsToInput(`${key}.${innerKey}`, {
              type: "api",
              message: innerValue[0],
            });
          });
        }
      });
    }
  }
  dispatch(setIsLoadingDeal(false));
  if (response.ok) {
    await getDealContacts(dispatch, dealId);
    dispatch(setDealSaveSuccess(true));
  }
};

const getDealPastActivities = async (dispatch, dealId) => {
  dispatch(setIsLoadingDeal(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/past_activities`
  );
  const responseData = await response.json();
  if (response.status === 200) {
    const reformattedRes = reformatPastActivitiesResponse(
      responseData.past_activities
    );
    dispatch(setDealPastActivities(reformattedRes));
  } else {
    dispatch(setDealError(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingDeal(false));
};

const putDealPastActivities = async (dispatch, dealId, pastActivities) => {
  dispatch(setIsLoadingDeal(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/past_activities`,
    {
      method: "PUT",
      body: JSON.stringify(pastActivities),
      headers: { "Content-type": "application/json" },
    }
  );
  if (!response.ok) {
    const err = await response.json();
    dispatch(setDealError(err?.errors[0]?.message));
  }
  dispatch(setIsLoadingDeal(false));
  if (response.ok) {
    dispatch(setDealSaveSuccess(true));
  }
};

const patchDealStatus = async (dispatch, dealId, dealStatus) => {
  dispatch(setIsLoadingDeal(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}`,
    {
      method: "PATCH",
      body: JSON.stringify(dealStatus),
      headers: { "Content-type": "application/json" },
    }
  );

  if (!response.ok) {
    const err = await response.json();
    dispatch(setDealError(err?.errors[0]?.message));
  }
  if (response.ok) {
    dispatch(setDealStatus(dealStatus.status));
    dispatch(setDealSaveSuccess(true));
  }
  dispatch(setIsLoadingDeal(false));
};

export {
  getDealInfo,
  getDealContacts,
  putDealContacts,
  getDealPastActivities,
  putDealPastActivities,
  patchDealStatus,
};
