import React from 'react';
import DocsTile from './DocsTile';
import MainTile from './MainTile';

import './styles.scss';

const RWIApplication = () => {
  return (
    <section className='QM-rwiApplication_container'>
      <MainTile />
      <DocsTile />
    </section>
  );
}

export default RWIApplication;
