import format from "date-fns/format";
import parse from "date-fns/parse";

const DATE_FORMAT = "MM/dd/yyyy";
const DATE_TIMING_FORMAT = "dd/MM/yyyy";
const REQUEST_DATE_FORMAT = "MM/dd/yyyy";
const NEW_DEAL_DATE_REQUEST_FORMAT = "M/d/yyyy";
const QUERY_DATE_FORMAT = "yyyy-MM-dd";
const TOAST_CLOSE_DELAY = 2500;

const parseDate = (str, formatStr, locale) => {
  const parsed = parse(str, formatStr, new Date(), { locale });
  return parsed;
};
const parseQueryDate = (str) => {
  const parsed = parse(str, QUERY_DATE_FORMAT, new Date());
  return parsed;
};

const formatDate = (date, formatStr, locale) => {
  return format(date, formatStr, { locale });
};

const reformatTimestamp = (timestamp) => {
  const TIMESTAMP_ORIGINAL_FORMAT = `${DATE_FORMAT}_HH:mm:ss`;
  const TIME_FORMAT = "hh:mm a";

  const parsed = parse(timestamp, TIMESTAMP_ORIGINAL_FORMAT, new Date());

  const date = format(parsed, DATE_FORMAT);
  const time = format(parsed, TIME_FORMAT);

  return {
    date,
    time,
  };
};

const extractCurrency = (currencyStr) => {
  const clearedStr = currencyStr
    .split("")
    .filter((el) => el !== "$" && el !== ",")
    .join("");
  return parseFloat(clearedStr);
};
const extractNumber = (numStr) => {
  const zeroMatch = ["na", "n/a"];
  const hasZeroValue = zeroMatch.some(
    (value) => value === numStr.toLowerCase()
  );
  if (hasZeroValue) {
    return 0;
  } else {
    return parseInt(numStr);
  }
};
const getDecimals = (decimalNum) => {
  const numberPartsArr = `${decimalNum}`.split(".");
  let decimals = numberPartsArr[1].split("");
  const firstZeroIndex = decimals.findIndex((val) => val === "0");
  if (firstZeroIndex < 0) {
    return decimalNum;
  } else if (firstZeroIndex === 0) {
    return numberPartsArr[0];
  } else {
    return `${numberPartsArr[0]}.${decimals.slice(0, firstZeroIndex).join("")}`;
  }
};
const getNumArr = (numStr, separator) => {
  if (numStr.length <= 5) return [`$${numStr}`];

  let resultArr = numStr.split(separator);
  resultArr[0] = `$${resultArr[0]}${separator}`;
  return resultArr;
};

const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    const fixedNum = num.toFixed(0);
    let numArr = `${fixedNum}`.split("");
    numArr.splice(-3, 0, ",");
    let numStr = numArr.join("");
    return getNumArr(numStr, ",");
  } else if (num >= 1000000 && num < 1000000000) {
    if (!(num % 1000000)) {
      return [`$${num / 1000000}M`];
    } else {
      let numStr = `${getDecimals((num / 1000000).toFixed(2))}M`;
      return getNumArr(numStr, ".");
    }
  } else if (num >= 1000000000) {
    if (!(num % 1000000000)) {
      return [`$${num / 1000000000}B`];
    } else {
      let numStr = `${getDecimals((num / 1000000000).toFixed(2))}B`;
      return getNumArr(numStr, ".");
    }
  } else if (num <= 999) {
    return [`$${num}`];
  }
};

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const formatPropName = (str) => capitalizeFirstLetter(str).split("_").join(" ");

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export {
  DATE_FORMAT,
  DATE_TIMING_FORMAT,
  QUERY_DATE_FORMAT,
  REQUEST_DATE_FORMAT,
  NEW_DEAL_DATE_REQUEST_FORMAT,
  TOAST_CLOSE_DELAY,
  parseDate,
  parseQueryDate,
  formatDate,
  reformatTimestamp,
  extractCurrency,
  extractNumber,
  numFormatter,
  capitalizeFirstLetter,
  formatPropName,
  debounce,
};
