import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getAssignLawyerList,
  postLawyer,
} from "../../../../../api/assignUserApi";
import {
  lawyerListSelector,
  lawyerPendingListSelector,
  lawyerAcceptedListSelector,
  assignUserErrSelector,
  assignUserSuccessSelector,
} from "../../../../../redux/selectors/assign-lawyer-selectors";
import { dealInfoSelector } from "../../../../../redux/selectors/deal-selectors";
import {
  setAssignUserErr,
  setAssignUserSuccess,
} from "../../../../../redux/reducers/assignLawyer-reducer";
import { SELF_CLOSE_DELAY } from "../../../../../helpers/broker-helpers";

import QMButton from "../../../common/QMButton";
import QMModal from "../../../common/QMModal";
import AssigneeSearch from "./AssigneeSearch/AssigneeSearch";
import QMToastMessage from "../../../common/QMToastMessage";
import AssignLawyerEmptyList from "./AssignLawyerEmptyList";
import AssignLawyerManageAccess from "./AssignLawyerManageAccess";
import AssignLawyerPendingList from "./AssignLawyerPendingList";

import "./styles.scss";

const AssignLawyerModal = ({ setInactiveStatus }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { dealId } = useParams();
  const dealInfo = useSelector(dealInfoSelector);
  const [searchResult, setSearchResult] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const lawyersContacts = useSelector(lawyerListSelector);
  const lawyerPendingList = useSelector(lawyerPendingListSelector);
  const lawyerAcceptedList = useSelector(lawyerAcceptedListSelector);
  const assignUserErr = useSelector(assignUserErrSelector);
  const assignUserSuccess = useSelector(assignUserSuccessSelector);
  const [showManageAccessModal, setShowManageAccessModal] = useState(false);

  const emptySearch = () => {
    setSearchInput("");
    setShowManageAccessModal(true);
  };

  const isEmptyLawyerList = (pendingList, acceptedList) => {
    const isEmpty = pendingList ? pendingList.concat(acceptedList) : [];
    if (isEmpty.length < 1) {
      return <AssignLawyerEmptyList />;
    }
    if (pendingList.length) {
      return <AssignLawyerPendingList data={pendingList} />;
    }
  };
  const handleManageAccessClose = () => {
    setSearchResult({});
    setShowManageAccessModal(false);
  };

  const handleManageAccessSubmit = async (dispatch, data) => {
    await postLawyer(dispatch, data, searchResult, dealId);
    handleManageAccessClose();
  };

  useEffect(() => {
    getAssignLawyerList(dispatch, dealId);
  }, [dispatch, dealId]);
  return (
    <QMModal>
      <section className="assign-lawyer-modal">
        <header>
          <h2 className="assign-lawyer-modal__title">
            {t("assign_lawyer.title")} {dealInfo?.name}
          </h2>
        </header>
        <section className="assign-lawyer-modal__search">
          <AssigneeSearch
            data={lawyersContacts}
            setSearchResult={setSearchResult}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <QMButton
            text={t("assign_lawyer.add")}
            customClassName={
              Object.keys(searchResult).length > 1
                ? "assign-lawyer-modal__btn-active"
                : "assign-lawyer-modal__btn-inactive"
            }
            disabled={Object.keys(searchResult).length < 1}
            onClick={() => emptySearch()}
          />
        </section>
        <section className="assign-lawyer-modal__body">
          {isEmptyLawyerList(lawyerPendingList, lawyerAcceptedList)}
        </section>
        <footer className="assign-lawyer-modal__footer">
          <QMButton
            text={t("assign_lawyer.done")}
            onClick={() => setInactiveStatus(false)}
          />
        </footer>
        {assignUserErr && (
          <QMToastMessage
            viewType="error"
            text={assignUserErr}
            iconClassName="icon-attention"
            onClose={() => dispatch(setAssignUserErr(null))}
          />
        )}
        {assignUserSuccess && (
          <QMToastMessage
            viewType="success"
            text={assignUserSuccess}
            iconClassName="icon-check-circle"
            onClose={() => {
              dispatch(setAssignUserSuccess(null));
            }}
            selfCloseDelay={SELF_CLOSE_DELAY}
          />
        )}
      </section>
      {showManageAccessModal && (
        <AssignLawyerManageAccess
          isNewUser
          userData={searchResult}
          handleManageAccessSubmit={handleManageAccessSubmit}
          handleClose={handleManageAccessClose}
        />
      )}
    </QMModal>
  );
};

export default AssignLawyerModal;
