import { addDays, subDays, addMonths, startOfToday, parse } from "date-fns";

export const URL_REGEXP =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/; // eslint-disable-line
export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
export const MONEY_REGEXP =
  /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\.[0-9][0-9])?$/;
export const LETTERS_NUMBERS_REGEXP = /^[a-zA-Z0-9\s.-]*$/;
export const NUMBER_NA_REGEXP = /^(?:0|[1-9]{1}[0-9]{0,2}|n\/a|na)$/i;
export const NUMBER_REGEXP = /^(?:0|[1-9]{1}[0-9]{0,2})$/i;
export const NUMBER_REGEXP_YEAR = /^[1-9]|1[0]+$/; ///^[1-10]+$/;
export const PHONE_REGEXP =
  /^\+?1?-*? \(?\d{3}(?:\)|[-|\s ])?\s*?\d{3}[-|\s]?\d{4}$/;
export const MONEY_WHOLE_THOUSANDS_REGEXP = /000$/;
export const MONEY_REMOVE_SIGNS = /[$,]/g;
export const TODAY = startOfToday();
export const MIN_DEAL_CLOSING_DATE = addDays(TODAY, 7);
export const MAX_DEAL_CLOSING_DATE = addMonths(TODAY, 18);
export const MIN_PURCHASE_AGREEMENT_DATE_DRAFT = subDays(TODAY, 60);
export const MIN_PURCHASE_AGREEMENT_DATE_FINAL = subDays(TODAY, 14);
export const MAX_PURCHASE_AGREEMENT_DATE = addMonths(TODAY, 6);
export const MAX_DUE_DILIGENCE_AMOUNT = 99;
export const MAX_INSURANCE_NUMBER = 100;
export const MAX_MONEY_INSURANCE = 50000;
export const MAX_POLICY_PERIOD = 10;
export const MIN_RETENTION_VALUE = 10000000;
export const MAX_RETENTION_VALUE = 5000000000;
export const MIN_DEAL_SIZE = 1000000;
export const MAX_DEAL_SIZE = 1000000000;
export const MIN_NAME = 2;
export const MAX_NAME = 35;
export const MAX_DEAL_NAME = 150;

export const ERROR_MESSAGE_MIN_RETENTION_VALUE =
  "Must be bigger than $10,000,000";
export const ERROR_MESSAGE_MAX_RETENTION_VALUE =
  "Must be lower than $5,000,000,000";
export const ERROR_MESSAGE_POLICY_NUMBER = "Only integers are allowed";
export const ERROR_MESSAGE_REQUIRED = "This field is mandatory";
export const ERROR_MESSAGE_MAX65 = "Name is too long";
export const ERROR_MESSAGE_MAX_YEAR = "Not more than 10 years";
export const ERROR_MESSAGE_MAX35 = "Please enter valid dollar ammount";
export const ERROR_MESSAGE_LETTERS_NUMBERS =
  "Invalid entry. Only letters & numbers are allowed";
export const ERROR_MESSAGE_NUMBERS = "Invalid entry. Only numbers are allowed";
export const ERROR_MESSAGE_MONEY_FORMAT = "Please, enter valid amount";
export const ERROR_MESSAGE_URL_FORMAT = "Accepted format: www.example.com";
export const ERROR_MESSAGE_EMAIL = "Please enter a valid email";
export const ERROR_MESSAGE_PHONE = "U.S. phone numbers must have 10 digits";
export const ERROR_MESSAGE_MIN_DATE =
  "Invalid entry. Please choose the date in the future";
export const ERROR_MESSAGE_MIN_DATE2 = `Invalid entry. Minimum possible closing date is ${parse(
  MIN_DEAL_CLOSING_DATE,
  "d MMM yyyy",
  new Date()
)}`;
export const ERROR_MESSAGE_MAX_DATE = `Invalid entry. Minimum possible closing date is ${parse(
  MAX_DEAL_CLOSING_DATE,
  "d MMM yyyy",
  new Date()
)}`;
export const ERROR_MESSAGE_DATE_FORMAT = (format) =>
  `Accepted entry - ${format.toLowerCase()}`;

export const ERROR_MESSAGE_MAX_NUMBER = "Number is too big. Max amount is 100";
export const ERROR_MESSAGE_MIN_NUMBER = "Number is too small. Min amount is 1";

export const ERROR_MESSAGE_MAX_MONEY_INSURANCE = "Must be > $50000";
export const ERROR_MESSAGE_MONEY_WHOLE_THOUSANDS =
  "Must end with whole thousands";
export const ERROR_MESSAGE_LIMIT = "Must be > than limit";
export const ERROR_MESSAGE_DEAL_SIZE = "Must be > $1,000,000";
export const ERROR_MESSAGE_MAX_DEAL_SIZE = "Must be < $1,000,000,000";
export const ERROR_MESSAGE_MIN_NAME = "Must be > 2 symbols";
export const ERROR_MESSAGE_MAX_NAME = "Must be < 35 symbols";
export const ERROR_MESSAGE_MAX_DEAL_NAME = "Must be < 150 symbols";
