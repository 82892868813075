import { authFetch } from "./fetchConfig";

import {
  setIsLoadingDealDocuments,
  setDealDocumentsList,
  setDealDocumentsError,
  setDealDocumentsSuccess,
} from "../redux/reducers/dealDocs-reducer";

import { humanFileSize } from "../helpers/docs-helpers";

const getDealDocuments = async (dispatch, dealId, docsPart) => {
  dispatch(setIsLoadingDealDocuments(true));
  const requestUrl = docsPart
    ? `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/${docsPart}/documents`
    : `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/documents`;
  const response = await authFetch(requestUrl);
  const resData = await response.json();
  if (response.status === 200) {
    const resKeys = Object.keys(resData);
    dispatch(setDealDocumentsList(resData[resKeys[0]]));
  } else {
    dispatch(setDealDocumentsList({}));
    dispatch(setDealDocumentsError(resData?.errors[0]));
  }
  dispatch(setIsLoadingDealDocuments(false));
};

const uploadDealDocument = async (
  dispatch,
  dealId,
  docsPart,
  filedata,
  directory_id,
  successMessage,
  shouldReturnAll
) => {
  dispatch(setIsLoadingDealDocuments(true));
  const body = new FormData();

  body.append("document_file", filedata);
  body.append("file_size", humanFileSize(filedata.size, true));
  body.append("directory_id", directory_id);

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/${docsPart}/documents`,
    {
      method: "POST",
      body,
    }
  );

  if (!response.ok) {
    const err = await response.json();
    dispatch(setDealDocumentsError(err?.errors[0]));
  }
  if (response.ok) {
    const docsToGet = shouldReturnAll ? undefined : docsPart;
    getDealDocuments(dispatch, dealId, docsToGet);
    dispatch(setDealDocumentsSuccess({ message: successMessage }));
  }
  dispatch(setIsLoadingDealDocuments(false));
};

const deleteDealDocument = async (
  dispatch,
  dealId,
  documentId,
  docsPart,
  successMessage
) => {
  dispatch(setIsLoadingDealDocuments(true));

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/documents/${documentId}`,
    {
      method: "DELETE",
    }
  );
  if (!response.ok) {
    const err = await response.json();
    console.error(err);
    dispatch(setDealDocumentsError(err?.errors[0]));
  } else {
    getDealDocuments(dispatch, dealId, docsPart);
    dispatch(setDealDocumentsSuccess({ message: successMessage }));
  }
  dispatch(setIsLoadingDealDocuments(false));
};

const getDealDocumentPdf = async (
  dispatch,
  dealId,
  documentId,
  setFileData
) => {
  dispatch(setIsLoadingDealDocuments(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/documents/${documentId}`
  );
  if (response.ok) {
    const resBlob = await response.blob();
    const newBlob = new Blob([resBlob]);
    const newUrl = window.URL.createObjectURL(newBlob);
    setFileData({ url: newUrl, blob: newBlob });
  } else {
    const err = await response.json();
    dispatch(setDealDocumentsError(err?.errors[0]));
  }
  dispatch(setIsLoadingDealDocuments(false));
};

const getDealAdminDocuments = async (dispatch, dealId) => {
  dispatch(setIsLoadingDealDocuments(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/csv_documents`
  );
  const resData = await response.json();
  if (response.status === 200) {
    const resKeys = Object.keys(resData);
    dispatch(setDealDocumentsList(resData[resKeys[0]]));
  } else {
    dispatch(setDealDocumentsList({}));
    dispatch(setDealDocumentsError(resData));
  }
  dispatch(setIsLoadingDealDocuments(false));
};

const uploadDealAdminDocument = async (
  dispatch,
  dealId,
  filedata,
  directory_id,
  successMessage
) => {
  dispatch(setIsLoadingDealDocuments(true));
  const body = new FormData();

  body.append("document_file", filedata);
  body.append("file_size", humanFileSize(filedata.size, true));
  body.append("directory_id", directory_id);

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/csv_documents`,
    {
      method: "POST",
      body,
    }
  );

  if (!response.ok) {
    const err = await response.json();
    dispatch(setDealDocumentsError(err?.errors[0]));
  }
  if (response.ok) {
    getDealAdminDocuments(dispatch, dealId);
    dispatch(setDealDocumentsSuccess({ message: successMessage }));
  }
  dispatch(setIsLoadingDealDocuments(false));
};

export {
  getDealDocuments,
  uploadDealDocument,
  deleteDealDocument,
  getDealDocumentPdf,
  getDealAdminDocuments,
  uploadDealAdminDocument,
};
