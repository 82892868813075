import { authFetch } from "./fetchConfig";
import { reformatDealInfoTab } from "../helpers/deal-helpers";
import {
  setDealError,
  setDealSaveSuccess,
} from "../redux/reducers/dealPage-reducer";
import {
  setIsLoadingDealInfo,
  setDealInfoData,
} from "../redux/reducers/dealInfoTab-reducer";
import { getDealInfo } from "./dealApi";

const getDealInfoTabInfo = async (dispatch, dealId) => {
  dispatch(setIsLoadingDealInfo(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/deal_info`
  );
  const responseData = await response.json();

  if (response.status === 200) {
    dispatch(setDealInfoData(responseData.deal_info));
  } else {
    dispatch(setDealError(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingDealInfo(false));
};

const putDealInfoTabInfo = async (dispatch, dealId, data, setErrorsToInput) => {
  dispatch(setIsLoadingDealInfo(true));
  const dataToSend = reformatDealInfoTab(data);

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/deal_info`,
    {
      method: "PUT",
      body: JSON.stringify(dataToSend),
      headers: { "Content-type": "application/json" },
    }
  );

  if (!response.ok) {
    const err = await response.json();
    dispatch(setDealError(err?.errors[0]?.message));
    if (err?.errors[0]?.detail) {
      const errEntries = Object.entries(err?.errors[0]?.detail);
      errEntries.forEach(([key, value]) =>
        setErrorsToInput(key, { type: "api", message: value[0] })
      );
    }
  }

  if (response.ok) {
    await getDealInfoTabInfo(dispatch, dealId);
    await getDealInfo(dispatch, dealId);
    dispatch(setDealSaveSuccess(true));
  }
  dispatch(setIsLoadingDealInfo(false));
};

export { getDealInfoTabInfo, putDealInfoTabInfo };
