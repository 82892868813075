import React from "react";

import AssignLawyerPendingListItem from "./AssingLawyerPendingListItem";

import "./styles.scss";

const AssignLawyerPendingList = ({ data }) => {
  return (
    <ul className="assign-lawyer-modal__pending-list">
      {data?.map((item, index) => {
        return <AssignLawyerPendingListItem item={item} key={index} />;
      })}
    </ul>
  );
};

export default AssignLawyerPendingList;
