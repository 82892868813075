export const lawyerListSelector = (state) =>
  state.assignLawyer.lawyersListData?.attorneys?.not_assigned;
export const manageAccessSelector = (state) =>
  state.assignLawyer.lawyersListData?.policies;
export const accessLevelSelector = (state) =>
  state.assignLawyer.lawyersListData?.actions;
export const lawyerPendingListSelector = (state) =>
  state.assignLawyer.lawyersListData?.attorneys?.pending;
export const lawyerAcceptedListSelector = (state) =>
  state.assignLawyer.lawyersListData?.attorneys?.accepted;

export const assignUserErrSelector = (state) =>
  state.assignLawyer.assignUserErr;
export const assignUserSuccessSelector = (state) =>
  state.assignLawyer.assignUserSuccess;
