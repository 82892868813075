export const dealBuyerSelector = (state) => state.dealPage.dealInfo.buyer;
export const dealSellerSelector = (state) => state.dealPage.dealInfo.seller;
export const dealInfoSelector = (state) => state.dealPage.dealInfo;
export const dealContactsSelector = (state) => state.dealPage.dealContacts;
export const dealPastActivitiesSelector = (state) =>
  state.dealPage.dealPastActivities;
export const dealLoadingSelector = (state) => state.dealPage.isLoadingDeal;
export const dealErrorSelector = (state) => state.dealPage.dealError;
export const dealSaveSuccessSelector = (state) =>
  state.dealPage.dealSaveSuccess;
