import { authFetch } from "./fetchConfig";
import {
  NEW_DEAL_DATE_REQUEST_FORMAT,
  extractCurrency,
  formatDate,
} from "../helpers";
import { getTokenData } from "../helpers/auth-helpers";
import {
  setDealsList,
  setIsLoadingDeals,
  setSelectedDealContacts,
  setIsLoadingSidebarTab,
  setRequiredDocumentsList,
  setProvidedDocumentsList,
  setSubmissionData,
  setDealsError,
} from "../redux/reducers/deals-reducer";

const getDeals = async (dispatch) => {
  const { role } = getTokenData();
  dispatch(setIsLoadingDeals(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals?mode=${role}`
  );
  const resData = await response.json();
  if (response.status === 200) {
    dispatch(setDealsList(resData.deals));
  } else {
    console.error("something went wrong: ", resData);
    dispatch(setDealsError(resData?.errors[0]));
  }
  dispatch(setIsLoadingDeals(false));
};

const getContacts = async (dispatch, dealId) => {
  dispatch(setIsLoadingSidebarTab(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/contacts`
  );
  const resData = await response.json();
  if (response.status === 200) {
    dispatch(setSelectedDealContacts(resData.deal_contacts));
  } else {
    console.error("something went wrong: ", resData);
  }
  dispatch(setIsLoadingSidebarTab(false));
};

const getRequiredDocs = async (dispatch, dealId) => {
  dispatch(setIsLoadingSidebarTab(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/expected_documents`
  );
  const resData = await response.json();
  console.log("req.docs resData", resData);
  if (response.status === 200) {
    dispatch(setRequiredDocumentsList(resData.expected_documents));
  } else {
    console.error("something went wrong: ", resData);
  }
  dispatch(setIsLoadingSidebarTab(false));
};

const getProvidedDocs = async (dispatch, dealId) => {
  dispatch(setIsLoadingSidebarTab(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/provided_documents`
  );
  const resData = await response.json();
  console.log("prov.docs resData", resData);
  if (response.status === 200) {
    dispatch(setProvidedDocumentsList(resData.provided_documents));
  } else {
    console.error("something went wrong: ", resData);
  }
  dispatch(setIsLoadingSidebarTab(false));
};

// temporary stub till backend is ready
const getSubmission = async (dispatch, dealId) => {
  dispatch(setIsLoadingSidebarTab(true));
  const submissionPromise = () =>
    new Promise((resolve) =>
      setTimeout(() => {
        resolve({
          transactions: {},
          insurance_requirements: {},
          due_dilligence: {},
          timing: {},
        });
      }, 1000)
    );
  const resData = await submissionPromise();
  dispatch(setSubmissionData(resData));
  dispatch(setIsLoadingSidebarTab(false));
};

const searchDNBCompany = async (searchStr, setLoading, setData) => {
  setLoading(true);
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/companies/d_search?q=${searchStr}`
  );
  const resData = await response.json();
  if (response.ok) {
    setData(resData?.companies);
  }
  setLoading(false);
};

const createNewDeal = async (
  dealData,
  systemIdsData,
  setIsLoading,
  setError,
  handleSuccess,
  setErrorsToInput,
  withSubmit
) => {
  setIsLoading(true);
  const {
    buyer_name,
    buyer_website,
    buyer_is_subsidiary,
    buyer_parent_company_name,
    buyer_parent_company_website,

    seller_name,
    seller_website,
    seller_target,
    seller_subsidiary_company_name,
    seller_subsidiary_company_website,

    applicant,
    closing_date,
    deal_size,
    jurisdiction,
    deal_name,

    // had_coverage_in_past

    //waiting for BE
    integration_lead_present,
    integration_lead_first_name,
    integration_lead_last_name,
    integration_lead_email,

    external_data_room_count,
    external_data_room_url_first,
    external_data_room_url_second,

    organization_chart_file,
    organization_chart_file_present,

    sales_purchase_agreement_file,
    sales_purchase_agreement_file_present,
  } = dealData;

  const {
    buyer_duns_number,
    buyer_parent_duns_number,
    seller_duns_number,
    seller_subsidiary_duns_number,
  } = systemIdsData;
  const body = new FormData();
  body.append("buyer_name", buyer_name);
  body.append("buyer_duns_number", buyer_duns_number);
  body.append("buyer_is_subsidiary", buyer_is_subsidiary);
  body.append("buyer_website", buyer_website);
  if (buyer_is_subsidiary) {
    body.append("buyer_parent_company_name", buyer_parent_company_name);
    body.append("buyer_parent_company_website", buyer_parent_company_website);
    body.append("buyer_parent_duns_number", buyer_parent_duns_number);
  }

  body.append("seller_name", seller_name);
  body.append("seller_duns_number", seller_duns_number);
  body.append("seller_website", seller_website);
  body.append("seller_target", seller_target);
  if (seller_target === "subsidiary") {
    body.append(
      "seller_subsidiary_company_name",
      seller_subsidiary_company_name
    );
    body.append(
      "seller_subsidiary_company_website",
      seller_subsidiary_company_website
    );
    body.append("seller_subsidiary_duns_number", seller_subsidiary_duns_number);
  }

  body.append("applicant", applicant);
  body.append("deal_size", extractCurrency(deal_size));
  body.append(
    "closing_date",
    formatDate(closing_date, NEW_DEAL_DATE_REQUEST_FORMAT)
  );
  body.append("jurisdiction", jurisdiction.value.toLowerCase());
  body.append("deal_name", deal_name);

  if (integration_lead_present !== undefined) {
    body.append("integration_lead_present", integration_lead_present);
  }
  if (integration_lead_present) {
    body.append("integration_lead_first_name", integration_lead_first_name);
    body.append("integration_lead_last_name", integration_lead_last_name);
    body.append("integration_lead_email", integration_lead_email);
  }

  if (
    external_data_room_count !== undefined &&
    external_data_room_count !== null
  ) {
    body.append("external_data_room_count", external_data_room_count);
  }
  if (external_data_room_count === "1") {
    body.append("external_data_room_url_first", external_data_room_url_first);
  }
  if (external_data_room_count === "2") {
    body.append("external_data_room_url_first", external_data_room_url_first);
    body.append("external_data_room_url_second", external_data_room_url_second);
  }

  if (
    organization_chart_file_present !== undefined &&
    organization_chart_file_present !== null
  ) {
    body.append(
      "organization_chart_file_present",
      organization_chart_file_present
    );
  }
  if (organization_chart_file_present) {
    body.append("organization_chart_file", organization_chart_file[0]);
  }

  if (
    sales_purchase_agreement_file_present !== undefined &&
    sales_purchase_agreement_file_present !== null
  ) {
    body.append(
      "sales_purchase_agreement_file_present",
      sales_purchase_agreement_file_present
    );
  }
  if (sales_purchase_agreement_file_present) {
    body.append(
      "sales_purchase_agreement_file",
      sales_purchase_agreement_file[0]
    );
  }

  const url = withSubmit
    ? `${process.env.REACT_APP_BASE_API_URL}/deals_submission`
    : `${process.env.REACT_APP_BASE_API_URL}/deals`;
  const response = await authFetch(url, {
    method: "POST",
    body,
  });
  const resData = await response.json();
  if (!response.ok) {
    setError(resData?.errors[0]);
    if (resData?.errors[0]?.detail) {
      const errEntries = Object.entries(resData?.errors[0]?.detail);
      errEntries.forEach(([key, value]) =>
        setErrorsToInput(key, { type: "api", message: value[0] })
      );
    }
  } else if (response.status === 201) {
    handleSuccess(resData.deal.id);
  }
  setIsLoading(false);
};

const createNewDealAdminDeal = async (
  dealData,
  setIsLoading,
  setError,
  handleSuccess,
  withSubmit
) => {
  setIsLoading(true);
  const {
    brokerage,
    broker_first_name,
    broker_last_name,
    broker_email,

    buyer_name,
    buyer_website,
    buyer_law_firm_name,
    buyer_is_subsidiary,
    buyer_parent_company_name,
    buyer_parent_company_website,

    seller_name,
    seller_website,
    seller_law_firm_name,
    seller_target,
    seller_subsidiary_company_name,
    seller_subsidiary_company_website,

    applicant,
    closing_date,
    deal_size,
    jurisdiction,
    deal_name,

    integration_lead_present,
    integration_lead_first_name,
    integration_lead_last_name,
    integration_lead_email,

    external_data_room_count,
    external_data_room_url_first,
    external_data_room_url_second,
  } = dealData;

  const body = new FormData();

  body.append("brokerage", brokerage);
  body.append("broker_first_name", broker_first_name);
  body.append("broker_last_name", broker_last_name);
  body.append("broker_email", broker_email);

  body.append("buyer_name", buyer_name);
  body.append("buyer_is_subsidiary", buyer_is_subsidiary);
  body.append("buyer_website", buyer_website);
  body.append("buyer_law_firm_name", buyer_law_firm_name);
  if (buyer_is_subsidiary) {
    body.append("buyer_parent_company_name", buyer_parent_company_name);
    body.append("buyer_parent_company_website", buyer_parent_company_website);
  }

  body.append("seller_name", seller_name);
  body.append("seller_website", seller_website);
  body.append("seller_target", seller_target);
  body.append("seller_law_firm_name", seller_law_firm_name);
  if (seller_target === "subsidiary") {
    body.append(
      "seller_subsidiary_company_name",
      seller_subsidiary_company_name
    );
    body.append(
      "seller_subsidiary_company_website",
      seller_subsidiary_company_website
    );
  }

  body.append("applicant", applicant);
  body.append("deal_size", extractCurrency(deal_size));
  body.append(
    "closing_date",
    formatDate(closing_date, NEW_DEAL_DATE_REQUEST_FORMAT)
  );
  body.append("jurisdiction", jurisdiction.value.toLowerCase());
  body.append("deal_name", deal_name);

  if (integration_lead_present !== undefined) {
    body.append("integration_lead_present", integration_lead_present);
  }
  if (integration_lead_present) {
    body.append("integration_lead_first_name", integration_lead_first_name);
    body.append("integration_lead_last_name", integration_lead_last_name);
    body.append("integration_lead_email", integration_lead_email);
  }

  if (
    external_data_room_count !== undefined &&
    external_data_room_count !== null
  ) {
    body.append("external_data_room_count", external_data_room_count);
    body.append("external_data_room_url_first", external_data_room_url_first);
  }

  if (external_data_room_count === "2") {
    body.append("external_data_room_url_second", external_data_room_url_second);
  }

  const url = withSubmit
    ? `${process.env.REACT_APP_BASE_API_URL}/deals_submission`
    : `${process.env.REACT_APP_BASE_API_URL}/deals`;
  const response = await authFetch(url, {
    method: "POST",
    body,
  });
  const resData = await response.json();
  if (!response.ok) {
    setError(resData?.errors[0]);
  } else if (response.status === 201) {
    handleSuccess(resData.deal.id);
  }
  setIsLoading(false);
};

export {
  getDeals,
  getContacts,
  getRequiredDocs,
  getProvidedDocs,
  getSubmission,
  searchDNBCompany,
  createNewDeal,
  createNewDealAdminDeal,
};
