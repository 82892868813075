import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import uniqid from "uniqid";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";

import {
  QMMapToolTipMarker,
  QMMapJurisdictionMarker,
  QMMapMarker,
  QMMapPlusZoom,
  QMMapMinusZoom,
} from "../../common/QMMapMarker";
import QMTile from "../../common/QMTile";

import dealAssetsMap from "./dealAssetsMap.json";

import "./styles.scss";

const RiskProfileDealAssets = ({ data, header }) => {
  const { t } = useTranslation();
  const [position, setPosition] = useState({ coordinates: [-90, 40], zoom: 1 });

  const handleZoomPercentage = () => {
    if (position.zoom === 1) return `100%`;
    if (position.zoom === 2) return `50%`;
    if (position.zoom === 4) return `25%`;
  };

  const handleZoomIn = () => {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  };

  const handleMoveEnd = (position) => {
    setPosition(position);
  };

  return (
    <QMTile>
      <h2 className="risk-profile__header">{header}</h2>
      <div className="risk-profile__map-body">
        <ComposableMap
          projectionConfig={{
            scale: 800,
            center: [-90, 40],
          }}
          style={{ background: "#F9FAFB", height: "280px", width: "100%" }}
          className="risk-profile__map"
        >
          <ZoomableGroup
            zoom={position.zoom}
            center={position.coordinates}
            onMoveEnd={handleMoveEnd}
          >
            <Geographies geography={dealAssetsMap}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={
                      data?.some((item) => geo.properties.name === item.country)
                        ? "rgba(0, 166, 169, 0.4)"
                        : "rgba(0, 166, 169, 0.1)"
                    }
                    stroke="#1B91B6"
                    style={{
                      default: { outline: "none" },
                      hover: {
                        outline: "none",
                      },
                      pressed: { outline: "none" },
                    }}
                  />
                ))
              }
            </Geographies>
            {data?.map(({ name, address, asset_subject, coordinates }) => {
              return asset_subject !== "deal_jurisdiction" ? (
                <Marker key={uniqid(name)} coordinates={coordinates}>
                  <g transform="translate(-28 -52)" cursor="default">
                    <QMMapToolTipMarker
                      name={name}
                      address={address}
                      mainColor="#00A6A9"
                      secondaryColor="#ffffff"
                      width="50"
                      height="50"
                      asset_subject={asset_subject}
                    />
                  </g>
                </Marker>
              ) : (
                <></>
              );
            })}
          </ZoomableGroup>
        </ComposableMap>
        <div className="risk-profile__map-control">
          <QMMapMinusZoom onClick={handleZoomOut} />
          <span className="risk-profile__map-control-size">
            {handleZoomPercentage()}
          </span>
          <QMMapPlusZoom onClick={handleZoomIn} />
        </div>
      </div>
      <section className="risk-profile__map-info">
        <div>
          <QMMapJurisdictionMarker mainColor="#1A1A36" />
          <span className="risk-profile__description">
            {t("risk_profile.deal_jurisdiction")}
          </span>
          <p>
            {data?.map((item, index) => {
              return item.asset_subject === "deal_jurisdiction" ? (
                <span className="risk-profile__asset-location" key={index}>
                  {item.name}
                </span>
              ) : null;
            })}
          </p>
        </div>
        <div>
          <QMMapMarker
            mainColor="#00A6A9"
            secondaryColor="#ffffff"
            width="17"
            height="17"
          />
          <span className="risk-profile__description">
            {t("risk_profile.buyers_assets")}
          </span>
          <p>
            {t("risk_profile.domestic")}
            {data?.map((item, index) => {
              return item.asset_subject === "buyer" &&
                item.country === "United States" ? (
                <span className="risk-profile__asset-location" key={index}>
                  {item.name}
                </span>
              ) : null;
            })}
          </p>
          <p>
            {t("risk_profile.international")}
            {data?.map((item, index) => {
              return item.asset_subject === "buyer" &&
                item.country !== "United States" ? (
                <span className="risk-profile__asset-location" key={index}>
                  {item.country}
                </span>
              ) : null;
            })}
          </p>
        </div>
        <div>
          <QMMapMarker
            mainColor="#336B7D"
            secondaryColor="#ffffff"
            width="17"
            height="17"
          />
          <span className="risk-profile__description">
            {t("risk_profile.targets_assets")}
          </span>
          <p>
            {t("risk_profile.domestic")}
            {data?.map((item, index) => {
              return item.asset_subject === "target" &&
                item.country === "United States" ? (
                <span className="risk-profile__asset-location" key={index}>
                  {item.name}
                </span>
              ) : null;
            })}
          </p>
          <p>
            {t("risk_profile.international")}
            {data?.map((item, index) => {
              return item.asset_subject === "target" &&
                item.country !== "United States" ? (
                <span className="risk-profile__asset-location" key={index}>
                  {item.country}
                </span>
              ) : null;
            })}
          </p>
        </div>
      </section>
    </QMTile>
  );
};
export default RiskProfileDealAssets;
