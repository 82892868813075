import { authFetch } from "./fetchConfig";
import {
  reformatInsuranceCompliance,
  reformatRWInsurance,
} from "../helpers/deal-helpers";

import {
  setDealError,
  setDealSaveSuccess,
} from "../redux/reducers/dealPage-reducer";
import {
  setInsuranceComplianceInfo,
  setInsuranceInfo,
  setIsLoadingInsurance,
} from "../redux/reducers/insuranceTab-reducer";

const getInsuranceTabInfo = async (dispatch, dealId) => {
  dispatch(setIsLoadingInsurance(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/insurance`
  );
  const responseData = await response.json();

  if (response.status === 200) {
    dispatch(setInsuranceInfo(responseData.insurance));
  } else {
    dispatch(setDealError(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingInsurance(false));
};

const putInsuranceTabInfo = async (dispatch, dealId, data) => {
  dispatch(setIsLoadingInsurance(true));
  const dataToSend = reformatRWInsurance(data);

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/insurance`,
    {
      method: "PUT",
      body: JSON.stringify(dataToSend),
      headers: { "Content-type": "application/json" },
    }
  );

  if (!response.ok) {
    const err = await response.json();
    dispatch(setDealError(err?.errors[0]?.message));
  }

  if (response.ok) {
    dispatch(setInsuranceInfo(dataToSend));
    dispatch(setDealSaveSuccess(true));
  }
  dispatch(setIsLoadingInsurance(false));
};

const getInsuranceComplianceTabInfo = async (dispatch, dealId) => {
  dispatch(setIsLoadingInsurance(true));
  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/insurance_compliance`
  );
  const responseData = await response.json();

  if (response.status === 200) {
    const reformatData =
      responseData.insurance_compliance.compliance_list.filter(
        (item) => item.name !== null
      );
    dispatch(setInsuranceComplianceInfo(reformatData));
  } else {
    dispatch(setDealError(responseData?.errors[0]?.message));
  }
  dispatch(setIsLoadingInsurance(false));
};

const putInsuranceComplianceTabInfo = async (dispatch, dealId, data) => {
  dispatch(setIsLoadingInsurance(true));
  const dataToSend = reformatInsuranceCompliance(data);

  const response = await authFetch(
    `${process.env.REACT_APP_BASE_API_URL}/deals/${dealId}/rwi_application/insurance_compliance`,
    {
      method: "PUT",
      body: JSON.stringify(dataToSend),
      headers: { "Content-type": "application/json" },
    }
  );

  if (!response.ok) {
    const err = await response.json();
    dispatch(setDealError(err?.errors[0]?.message));
  }

  if (response.ok) {
    dispatch(setInsuranceComplianceInfo(dataToSend.compliance_list));
    dispatch(setDealSaveSuccess(true));
  }
  dispatch(setIsLoadingInsurance(false));
};

export {
  getInsuranceTabInfo,
  putInsuranceTabInfo,
  getInsuranceComplianceTabInfo,
  putInsuranceComplianceTabInfo,
};
