import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dealId: null,
  isLoadingDeal: false,
  dealInfo: {},
  dealContacts: {},
  dealPastActivities: {},
  dealError: null,
  dealSaveSuccess: false,
};

export const dealPageReducer = createSlice({
  name: "dealPage",
  initialState,
  reducers: {
    setDealId: (state, action) => {
      state.dealId = action.payload;
    },
    setIsLoadingDeal: (state, action) => {
      state.isLoadingDeal = action.payload;
    },
    setDealInfo: (state, action) => {
      state.dealInfo = action.payload;
    },
    setDealContacts: (state, action) => {
      state.dealContacts = action.payload;
    },
    setDealPastActivities: (state, action) => {
      state.dealPastActivities = action.payload;
    },
    setDealError: (state, action) => {
      state.dealError = action.payload;
    },
    setDealSaveSuccess: (state, action) => {
      state.dealSaveSuccess = action.payload;
    },
    setDealStatus: (state, action) => {
      state.dealInfo.status = action.payload;
    },
  },
});
export const {
  setDealId,
  setIsLoadingDeal,
  setDealInfo,
  setDealContacts,
  setDealPastActivities,
  setDealError,
  setDealSaveSuccess,
  setDealStatus,
} = dealPageReducer.actions;

export default dealPageReducer.reducer;
