import React from "react";
import QMTile from "../../common/QMTile";
import RWIAllFilesTree from "../RWIApplication/RWIAllFilesTree/RWIAllFilesTree";

import "./styles.scss";

const FilesFolder = () => {
  return (
    <section className="grid-2-col QM-filesFolder_container">
      <QMTile>
        <RWIAllFilesTree />
      </QMTile>
      <QMTile>{/* underwriting docs are going here */}</QMTile>
    </section>
  );
};

export default FilesFolder;
