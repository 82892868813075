import React, { useState } from "react";
import { QMTextInput } from "../QMInputs";
import useOuterClick from "../../../../helpers/useOuterClick";
import { createAutocompleteRef } from "../../../../helpers/broker-helpers";
import useDNBSearch from "./useDNBSearch";
import QMLoader from "../../../common/QMLoader/QMLoader";

import "./styles.scss";

const QMDNBSearch = ({
  inputProps,
  register,
  setValue,
  watch,
  trigger,
  name,
  selectedPrediction,
  predictionSetter,
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const valueWatcher = watch(name);
  const { suggestions, setShouldFetch, isLoading } = useDNBSearch({
    searchString: valueWatcher,
  });
  const autocompleteRef = useOuterClick((e) => {
    createAutocompleteRef(
      valueWatcher,
      name,
      selectedPrediction,
      predictionSetter,
      setIsInputFocused,
      setValue
    );
  });
  const handleOptionSelect = (e, option) => {
    e.preventDefault();
    setShouldFetch(false);
    predictionSetter(option);
    setValue(name, option.business_name);
    trigger(name);
    setIsInputFocused(false);
  };

  const displayPredictions = (suggestions) => {
    return suggestions?.length ? (
      <ul className={`QM-autocomplete_results`}>
        {suggestions?.map((prediction, index) => (
          <li
            key={prediction.duns_number}
            className="QM-autocomplete_results_item"
            onClick={(e) => {
              handleOptionSelect(e, prediction);
            }}
          >
            <p>{prediction?.business_name}</p>
            <p>{`${prediction?.address?.street}, ${prediction?.address?.city}, ${prediction?.address?.state}`}</p>
          </li>
        ))}
      </ul>
    ) : null;
  };

  return (
    <section className="QM-autocomplete" ref={autocompleteRef}>
      <QMTextInput
        {...register(name)}
        {...inputProps}
        onFocus={(e) => {
          setIsInputFocused(true);
          setShouldFetch(true);
        }}
        onClick={(e) => {
          setIsInputFocused(true);
        }}
      />
      {isInputFocused && displayPredictions(suggestions)}
      {isLoading && (
        <div className="QM-autocomplete_loaderContainer">
          <QMLoader size="24" />
        </div>
      )}
    </section>
  );
};

export default QMDNBSearch;
