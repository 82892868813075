import Tippy from "@tippyjs/react";
import React from "react";

import "./styles.scss";

const QMMapToolTipMarker = ({ name, address, asset_subject }) => {
  const renderMarker = (data) => {
    if (data === "buyer")
      return (
        <svg
          width="50"
          height="50"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
            fill="#00A6A9"
            stroke="#00A6A9"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
            fill="#ffffff"
            stroke="#ffffff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    if (data === "target")
      return (
        <svg
          width="50"
          height="50"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
            fill="#336B7D"
            stroke="#336B7D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
            fill="#ffffff"
            stroke="#ffffff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    if (data === "deal_jurisdiction")
      return (
        <svg
          width="50"
          height="50"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8125 3.37478C11.8125 2.84262 11.6614 2.3214 11.377 1.87164C11.0925 1.42189 10.6863 1.06207 10.2055 0.833968C9.72474 0.605868 9.1891 0.518852 8.66083 0.583028C8.13255 0.647204 7.63333 0.859937 7.22113 1.19652C6.80894 1.5331 6.50069 1.97972 6.3322 2.4845C6.16371 2.98927 6.14188 3.5315 6.26925 4.04818C6.39663 4.56487 6.66797 5.03482 7.05178 5.40345C7.43558 5.77207 7.9161 6.02425 8.4375 6.13068V16.0859C8.43756 16.2353 8.46732 16.3833 8.52504 16.5211L8.87906 17.3649C8.89093 17.3866 8.90842 17.4047 8.92969 17.4173C8.95097 17.4299 8.97526 17.4366 9 17.4366C9.02474 17.4366 9.04903 17.4299 9.07031 17.4173C9.09159 17.4047 9.10907 17.3866 9.12094 17.3649L9.47496 16.5211C9.53268 16.3833 9.56244 16.2353 9.5625 16.0859V6.13068C10.1973 6.00022 10.7677 5.65487 11.1776 5.15286C11.5875 4.65084 11.8117 4.02286 11.8125 3.37478ZM9.84375 3.37478C9.67687 3.37478 9.51374 3.32529 9.37499 3.23258C9.23623 3.13987 9.12809 3.00809 9.06423 2.85392C9.00037 2.69974 8.98366 2.53009 9.01621 2.36642C9.04877 2.20275 9.12913 2.05241 9.24713 1.93441C9.36513 1.81641 9.51547 1.73605 9.67914 1.70349C9.84281 1.67094 10.0125 1.68764 10.1666 1.75151C10.3208 1.81537 10.4526 1.92351 10.5453 2.06227C10.638 2.20102 10.6875 2.36415 10.6875 2.53103C10.6875 2.75481 10.5986 2.96942 10.4404 3.12765C10.2821 3.28588 10.0675 3.37478 9.84375 3.37478Z"
            fill="#1A1A36"
          />
        </svg>
      );
  };
  return (
    <Tippy
      theme="light"
      content={
        <div className="tippy-marker">
          <span className="tippy-marker__header">{name}</span>
          <p className="tippy-marker__body">{address}</p>
        </div>
      }
      className="focus-visible"
    >
      {renderMarker(asset_subject)}
    </Tippy>
  );
};

export default QMMapToolTipMarker;
