import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import QMButton from "../../../common/QMButton";
import QMAvatar from "../../../common/QMAvatar/QMAvatar";
import QMToolTipName from "../../../common/QMToolTipName";

import AssignLawyerManageAccess from "./AssignLawyerManageAccess";
import RemoveUserConfirmModal from "./RemoveUserConfirmModal";
import {
  removeAssignedUser,
  resendInvitation,
  updateUserPolicies,
} from "../../../../../api/assignUserApi";

import "./styles.scss";

const AssignLawyerPendingListItem = ({ item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { dealId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const handlePoliciesUpd = async (dispatch, data) => {
    await updateUserPolicies(dispatch, data, item?.policy_link);
    setIsModalOpen(false);
  };
  const cutEmail = item?.attorney_email ? (
    item?.attorney_email.length > 50 ? (
      <QMToolTipName text={item?.attorney_email} maxLength={50} />
    ) : (
      item?.attorney_email
    )
  ) : (
    ""
  );
  const handleRemoveAssignee = async () => {
    await removeAssignedUser(dispatch, item?.policy_link, dealId);
    setIsDeleteConfirmationOpen(false);
  };
  return (
    <li className="assign-lawyer-modal__pending-list-item">
      <section className="assign-lawyer-modal__pending-list-item__userInfo">
        <div className="assign-lawyer-modal__pending-list-item__userInfo__avatarWrapper">
          <QMAvatar name={item?.attorney_name} />
          <QMButton
            viewType="transparent"
            customClassName="assign-lawyer-modal__pending-list-item__userInfo__delete-btn"
            iconClassName="icon-trash"
            title={t("assign_lawyer.remove_assignee")}
            type="button"
            onClick={() => setIsDeleteConfirmationOpen(true)}
          />
        </div>
        <div>
          <span className="assign-lawyer-modal__pending-list-item__name">
            {item?.attorney_name}
          </span>
          <p className="assign-lawyer-modal__pending-list-item__email">
            {cutEmail}
          </p>
        </div>
      </section>
      <p className="assign-lawyer-modal__pending-list-item__status">
        {t("assign_lawyer.pending")}
      </p>
      <QMButton
        viewType="transparent"
        text={t("assign_lawyer.manage_access_btn")}
        customClassName={`QM-btn__manage-access-btn`}
        onClick={() => setIsModalOpen(true)}
      />
      <QMButton
        viewType="transparent"
        text={t("assign_lawyer.resend")}
        customClassName={`QM-btn__resend-btn`}
        onClick={() => {
          resendInvitation(dispatch, item?.policy_link, dealId);
        }}
      />
      {isModalOpen && (
        <AssignLawyerManageAccess
          userData={item}
          handleClose={() => setIsModalOpen(false)}
          handleManageAccessSubmit={handlePoliciesUpd}
        />
      )}
      {isDeleteConfirmationOpen && (
        <RemoveUserConfirmModal
          handleClose={() => setIsDeleteConfirmationOpen(false)}
          handleConfirm={handleRemoveAssignee}
          userData={item}
        />
      )}
    </li>
  );
};

export default AssignLawyerPendingListItem;
